import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";

const IndexPage = () => (
    <Layout>
      <section className="px-6 py-12 section bg-white">
        <div className="container">
          <div className="mx-auto lg:w-2/3">
            <div className="section">
              <h2 className="mb-5 text-4xl font-bold">
                Über mich
              </h2>
              <div className="content">
                <h3>Hallo, ich bin Flo.</h3>
                <p>Ich bin Student, Softwareentwickler und Technik-Enthusiast aus Braunschweig.</p>
                <p>In meiner Freizeit musiziere, wandere und laufe ich regelmäßig und engagiere mich ehrenamtlich hier und da.</p>
                <p>Ansonsten interessiere ich mich für Videoschnitt und -bearbeitung, sowie Print.</p>
                <p>Viel Spaß beim Rumstöbern. Was <Link to="technisches">technisch</Link> hinter dieser Seite steckt habe ich ebenfalls niedergeschrieben. Solltest Du Fragen oder andere Anliegen haben, scheu Dich nicht, mich zu <Link to="kontakt">kontaktieren</Link>.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
)

export default IndexPage
